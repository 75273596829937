/**
 * Navigation
 */

import {
	lockBodyScrolling,
	unlockBodyScrolling
} from '../../../shared/utils/scroll';

import SelectorEngine from '../../../shared/dom/selector-engine';
import Manipulator from '../../../shared/dom/manipulator';
import EventHandler from '../../../shared/dom/event-handler';

import Nav from '../../../shared/components/nav/nav';
import Drawer from '../../../shared/components/drawer/drawer';

// -------
// Private
// -------

const NAME      = 'menu';
const DATA_KEY  = `ifab.${NAME}`;
const EVENT_KEY = `.${DATA_KEY}`;
// const API_KEY    = '.data-api';

const pageMenu = SelectorEngine.findOne('#page-menu-drawer');

let mainNavInstance;

/**
 * Drawer (Navigationsoverlay)
 */
const setupDrawer = () => {
	const trigger = SelectorEngine.findOne('[aria-controls="page-menu-drawer"]');
	const drawer  = new Drawer(pageMenu, {
		closeButton: false,
		noHeader: 1,
		placement: 'end',
		// onInit   : (event) => {
		// 	const componentCloseButton = SelectorEngine.findOne('.drawer__header > .drawer-close', event.target);
		//
		// 	if (componentCloseButton) {
		// 		componentCloseButton.remove();
		// 	}
		// },
		onShow   : function() {
			Manipulator.addClass(trigger, '-active');
		},
		onHidden : () => {
			Manipulator.removeClass(trigger, '-active');
		}
	});

	EventHandler.on(trigger, 'click.ifab.pageMenuDrawer', (event) => {
		event.preventDefault();
		event.stopPropagation();

		if (event.delegateTarget.classList.contains('-active')) {
			drawer.hide();
		} else {
			drawer.show();
		}
	});
};

/**
 * Initialisierung der eigentlichen Navigationsliste.
 */
const navigation = () => {
	const nav = SelectorEngine.findOne('#main-nav');

	if (nav) {
		mainNavInstance = new Nav(nav, {
			closeByOutsideClick: false,
			closeByEsc         : false,
			labelSubmenu       : (window.pageInstance.tm) ? window.pageInstance.tm.translate('ariaLabelSubmenu') : 'Submenu for %s',
			labelSubmenuTrigger: (window.pageInstance.tm) ? window.pageInstance.tm.translate('ariaLabelSubmenuTrigger') : 'Show submenu for %s',
			useReveal          : true, // { visibleDisplay: 'flex' },
			onHide             : () => {
				// Manipulator.addClass(event.relatedMenu, '-will-hide');
			},
			onHidden           : () => {
				// Manipulator.removeClass(event.relatedMenu, '-will-hide');
			},
			onShow             : () => {
				// Manipulator.addClass(event.relatedMenu, '-will-show');
			},
			onShown            : () => {
				// Manipulator.removeClass(event.relatedMenu, '-will-show');
			}
		});

		mainNavInstance.showCurrentTree();

		// const curPageItem = SelectorEngine.findOne('[aria-current="page"]');
		//
		// if (appMenuBody && curPageItem) {
		// 	scrollElementIntoView(curPageItem, appMenuBody, 'vertical');
		// }
	}
};

const navigationPeer = ($element) => {
	const $nav = $element;

	let
		allSubs              = $nav.children('.nav-list').children('.-has-sub'),
		$firstFloorSub,
		$subItemWithSubLinks = $nav.find('.-sub').find('.-has-sub'),
		buttonToOpenSub,
		buttonToCloseSub,
		nameOfParentLink,
		$subOfSubLinksWithSubLinks;

	$(allSubs).each(function() {
		$firstFloorSub = $(this).find('.-sub');
		$firstFloorSub = $firstFloorSub[0];
		$($firstFloorSub).addClass('firstSub');
	});

	$subItemWithSubLinks.each(function() {
		nameOfParentLink           = $(this).children('a').html();
		$subOfSubLinksWithSubLinks = $(this).children('.-sub');
		buttonToOpenSub            = '<span class="openSub"><i class="fas fa-chevron-circle-right"></i></span>';
		buttonToCloseSub           = '<span class="closeSub"><i class="fas fa-chevron-circle-left"></i>' + nameOfParentLink + '</span>';

		$(this).children('a').after(buttonToOpenSub);
		$subOfSubLinksWithSubLinks.prepend(buttonToCloseSub);
	});

	_addEventHandlers();
};

const _addEventHandlers = () => {
	const $doc = $(document);

	$doc.on('click', '[data-nav="main"] .openSub', function(e) {
		beginSliding($(this), 'open');
		e.stopPropagation();
	});

	$doc.on('click', '[data-nav="main"] .closeSub', function(e) {
		beginSliding($(this), 'close');
		e.stopPropagation();
	});

	$doc.on('mouseover', '[data-nav="main"] > .-main > .nav-item', function() {
		looseFocusOfOpenedSub($(this));
	});

	$doc.on('click', function() {
		looseFocusOfOpenedSub($(this));
	});
};

const beginSliding = ($element, action) => {
	let
		$sub,
		$subBefore,
		heightSub,
		heightFirstFloorSub,
		$firstFloorSub;

	if(action === 'open') {
		$sub                = $element.siblings('.-sub');
		$firstFloorSub      = $element.parents('.firstSub');
		heightSub           = $sub.outerHeight();
		heightFirstFloorSub = $firstFloorSub.outerHeight();


		$element.parents('.-sub').removeClass('open');
		$sub.addClass('open');

		$sub.css({
			"transform" : "translateX(-50%)",
		});

	} else if(action === 'close') {
		$sub                = $element.parent('.-sub');
		$subBefore          = $element.parent('.-sub').parents('.-sub');
		$subBefore          = $subBefore[0];
		$firstFloorSub      = $element.parents('.firstSub');
		heightSub           = $($subBefore).outerHeight();
		heightFirstFloorSub = $firstFloorSub.outerHeight();

		$element.parents('.-sub').removeClass('open');
		$($subBefore).addClass('open');
		$sub.css({
			'transform' : 'translateX(50%)',
		});
	}

	if($subBefore !== undefined && $($subBefore).hasClass('firstSub')) {
		$firstFloorSub.parent('.-has-sub').removeClass('showSub');
		$firstFloorSub.css({
			"max-height" : '2000px',
			"min-height" : 0,
		});

	} else {
		$firstFloorSub.parent('.-has-sub').addClass('showSub');
		if(heightFirstFloorSub > heightSub) {
			$firstFloorSub.css({
				"max-height" : heightSub,
				"min-height" : 0,
			});
		} else {
			$firstFloorSub.css({
				"max-height" : 0,
				"min-height" : heightSub,
			});
		}
	}
};

const looseFocusOfOpenedSub = ($element) => {
	if(!$element.hasClass('showSub')) {
		$('body').find('[data-nav="main"]').find('.showSub').removeClass('showSub');
	}
};

/**
 * Initialisierung des mobilen Slide Menu.
 */
const slideNavigation = () => {
	const nav = SelectorEngine.findOne('#main-nav', pageMenu);

	if(!nav) {
		return;
	}

	Manipulator.addClass(nav, 'slide-menu');

	const slideNav = new SlideMenu(nav, {
		backLinkBefore : '<i class="far fa-arrow-left"></i>',
		submenuLinkAfter : '<i class="far fa-arrow-right"></i>',
	});

	EventHandler.on(nav, 'click', '.nav-link', (event) => {
		event.preventDefault();
		event.stopPropagation();

		const target = event.target;

		if(target.classList.contains('slide-menu__decorator')) {
			let navItem = SelectorEngine.parents(target, '.nav-item')[0];
			let subMenu = SelectorEngine.findOne('.nav-list', navItem);

			slideNav.navigateTo(subMenu);
		} else {
			window.location.href = event.delegateTarget.getAttribute('href');
		}
	});
};

// -------
// Public
// -------

const menu = () => {
	if (pageMenu) {
		const $collection = $('.page-header [data-nav="main"]');

		if($collection) {
			$collection.each(function() {
				navigationPeer($(this));
			});
		}

		setupDrawer();
		slideNavigation();
	}
};

// Export
export default menu;
