/**
 * Vendors ´Cookie consent´
 */

import {
	lockBodyScrolling,
	unlockBodyScrolling
} from '../../../shared/utils/scroll';

import SelectorEngine from '../../../shared/dom/selector-engine';
import EventHandler from '../../../shared/dom/event-handler';
import Manipulator from '../../../shared/dom/manipulator';

import Matomo from './tools/matomo';

// -------
// Private
// -------

// const MatomoInstance = null;
const MatomoInstance = new Matomo(null, {
	// setCookieDomain : '*.relaunch.landkreis-nordsachsen.de',
	siteId: (window.pageInstance.settings.statistic.matomo === undefined) ? 0 : window.pageInstance.settings.statistic.matomo.id,
	trackerDomain: (window.pageInstance.settings.statistic.matomo === undefined) ? '' : window.pageInstance.settings.statistic.matomo.library
	//onEnable     : () => {},
	//onDisable    : () => {}
});

// const GtmInstance = null;
// const GaInstance = null;

// ------
// Public
// ------

const init = () => {
	let inst = null;

	if (typeof ScCookie !== 'undefined') {
		inst = new ScCookie({
			on: {
				showPreferences: function () {
					lockBodyScrolling(this);
				},
				hidePreferences: function () {
					unlockBodyScrolling(this);
				},
				saveConsent: function (consent) {
					document.location.reload();
				},
				init: function (consent) {
					// Youtube?
					if (this.getConsent('youtube')) {
						const collection = SelectorEngine.find('[data-player-type="youtube"]');

						for (const element of collection) {
							EventHandler.trigger(element, 'disable.privacyMode');
						}
					}

					// Vimeo?
					if (this.getConsent('vimeo')) {
						const collection = SelectorEngine.find('[data-player-type="vimeo"]');

						for (const element of collection) {
							EventHandler.trigger(element, 'disable.privacyMode');
						}
					}

					// Googlemaps?
					if (this.getConsent('googlemaps')) {
						const collection = SelectorEngine.find('[data-ce="map"]');

						for (const element of collection) {
							let iframe = SelectorEngine.findOne('iframe', element);
							let src = Manipulator.getDataAttribute(iframe, 'map');
							let privacyMsg = SelectorEngine.findOne('.privacy-info', element);

							EventHandler.trigger(element, 'disable.privacyMode');
							iframe.src = src;
							Manipulator.removeDataAttribute(iframe, 'map');

							privacyMsg.outerHTML = '';
						}
					}

					// Matomo?
					// if (MatomoInstance && MatomoInstance.isReady) {
					// 	if (this.getConsent('matomo')) {
					// 		MatomoInstance.enable();
					// 	} else {
					// 		MatomoInstance.disable();
					// 	}
					// }

					if (this.getConsent('matomo')) {
						var _paq = window._paq = window._paq || [];
						/* tracker methods like "setCustomDimension" should be called before "trackPageView" */
						_paq.push(['trackPageView']);
						_paq.push(['enableLinkTracking']);
						(function() {
							var u="https://matomo.peer.eu/";
							_paq.push(['setTrackerUrl', u+'matomo.php']);
							_paq.push(['setSiteId', '2']);
							var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
							g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
						})();
					}


					// Google Analytics?
					// if (this.getConsent('googleanalytics')) {
					// 	document.cookie                  = 'ga-disable-UA-129301-1=false; expires=Thu, 31 Dec 2099 23:59:59 UTC; path=/';
					// 	window['ga-disable-UA-129301-1'] = false;
					// 	INSERT_GTM();
					// } else {
					// 	document.cookie                  = 'ga-disable-UA-129301-1=true; expires=Thu, 31 Dec 2099 23:59:59 UTC; path=/';
					// 	window['ga-disable-UA-129301-1'] = true;
					// 	INSERT_GTM();
					// }
				}
			}
		});
	}

	// Video: Aktivieren und abspielen
	// EventHandler.on(document.body, 'click', '[data-privacy-enable]', (ev) => {
	// 	console.log(Manipulator.getDataAttribute(ev.delegateTarget, 'privacy-enable'));
	// });

	// Custom-Trigger für Cookie-Einstellungen anzeigen.
	EventHandler.on(document.body, 'click', '[data-privacy-settings]', (event) => {
		event.stopPropagation();

		window.ifabCookieConsent.togglePreferences();
	});

	return window.ifabCookieConsent = inst;
};

// Export
export default init;
