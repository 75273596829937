/**
 * Main(-JS-File)
 */

import {getRootVar} from '../shared/utils/index';

import config from './config/config';
import l10n from './config/l10n';
import componentShared from '../shared';
import componentsCustom from './components/components';
import initial from './initial/initial';
import layout from './layout/layout';
import vendors from './vendors/vendors';

import TranslationManager from '../shared/service/translation-manager';

// --------
// Let's go
// --------

//
// In `window` das Objekt ´pageInstance´ zusammenstellen (Konfiguratione, Übersetzungen etc.)
//

window.pageInstance = window.pageInstance || {};

// Übersetzungen (l10n)
if (!window.pageInstance.tm) {
	window.pageInstance.tm = new TranslationManager();

	window.pageInstance.tm.addLanguages(l10n);
}

window.pageInstance.prefix = getRootVar('global-rootvar-prefix');
window.pageInstance.config = config;

document.addEventListener('DOMContentLoaded', function () {
	window.RootvarPrefix = getRootVar('global-rootvar-prefix');

	componentShared();
	componentsCustom();
	vendors();
	initial();
	layout();
});
